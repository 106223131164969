import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

const CV = ({
    data: {
      allMarkdownRemark: { edges },
  }
}) => {
  const cv = edges
  .map(edge => <div className="cv-content col-12" dangerouslySetInnerHTML={{ __html: edge.node.html }}></div>)
  return(
    <Layout pageTitle="CV">
        <SEO title="CV" />
        <div className="row">
          {cv}
        </div>
    </Layout>
  )
}

export default CV

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, filter: {fileAbsolutePath: {regex: "/cv.md/"}}) {
      edges {
        node {
          id
          html
          frontmatter {
            slug
            title
            year
          }
        }
      }
    }
  }
`
